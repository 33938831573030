import { ROUTES } from "~/helpers/routes/routes";

export default defineNuxtRouteMiddleware(async (to) => {
  const { creditAccountData } = useCreditAccount();
  const { cashAccountData } = useCashAccount();

  if (to.path === ROUTES.CREATE_CREDIT_SUCCESS && !creditAccountData.value.success) {
    return navigateTo(ROUTES.HOMEPAGE);
  }

  if (to.path === ROUTES.CREATE_CASH_SUCCESS && !cashAccountData.value.success) {
    return navigateTo(ROUTES.HOMEPAGE);
  }
});
